import { Fragment, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useAppContext, useScrollRestore } from 'hooks';

import {
  ProjectContainer,
  ProjectSection,
  ProjectSectionContent,
  ProjectTextRow,
  ProjectImage,
  ProjectSectionHeading,
  ProjectSectionText,
  ProjectHeader,
} from 'components/ProjectLayout';
import ThemeProvider, { useTheme } from 'components/ThemeProvider';
import SegmentedControl, { SegmentedControlOption } from 'components/SegmentedControl';
import { media } from 'utils/style';
import imageSprLessonBuilderLightPlaceholder from 'assets/spr-lesson-builder-light-placeholder.jpg';
import imageSprLessonBuilderDark from 'assets/pac/pickaclass.png';
import imageSprLessonBuilderDarkPlaceholder from 'assets/pac/pac-2.png';

import './index.css';
import { CustomCaraousel } from './carousel';
import { EndFooter } from 'pages/Home/footer/footer';
import { appJoxDescription, appJoxTitle } from 'pages/Home';

const title = 'Fastest Class Notification App';
const description =
  'Pick A Class is the fastest platform designed to notify ASU students about class openings.';
const roles = [
  'UX and UI Design',
  'Front End Development',
  'NodeJS Server',
  'Web Crawling',
];

const PickAClass = () => {
  const { themeId } = useTheme();
  const { dispatch } = useAppContext();
  useScrollRestore();

  const isDark = themeId === 'dark';
  const themes = ['dark', 'light'];

  const handleThemeChange = index => {
    dispatch({ type: 'setTheme', value: themes[index] });
  };

  return (
    <Fragment>
      <ProjectContainer className="spr">
        <Helmet>
          <title>{`Projects | ${title}`}</title>
          <meta name="description" content={description} />{' '}
          <meta name="og:description" content={description} />
          <meta
            property="og:image"
            content="https://i.ibb.co/WtQsRdm/Screenshot-2022-02-15-204105.png"
          />{' '}
          <meta property="og:title" content={`Projects | ${title}`} />
          <meta name="twitter:description" content={appJoxDescription} />
          <meta name="twitter:title" content={appJoxTitle} />
        </Helmet>

        <ProjectHeader
          title={title}
          description={description}
          url="https://pickaclass.app/"
          roles={roles}
        />
        <ProjectSection first>
          <ProjectSectionContent>
            <ProjectImage
              raised
              key={themeId}
              srcSet={`${imageSprLessonBuilderDark} 1280w, ${imageSprLessonBuilderDark} 2560w`}
              placeholder={
                isDark
                  ? imageSprLessonBuilderDarkPlaceholder
                  : imageSprLessonBuilderLightPlaceholder
              }
              sizes={`(max-width: ${media.mobile}px) 100vw, (max-width: ${media.tablet}px) 800px, 1000px`}
              alt="Wicked Maine Lobster Website"
            />
          </ProjectSectionContent>
        </ProjectSection>
        <ProjectSection>
          <ProjectTextRow>
            <ProjectSectionHeading>The problem</ProjectSectionHeading>
            <ProjectSectionText>
              At large universities like ASU, students often struggle to enroll in classes
              with their preferred professors. Due to high demand and limited
              availability, these classes fill up quickly, leaving many students
              scrambling to find alternatives. As a result, students may miss out on the
              opportunity to learn from top-notch professors or end up in classes that are
              not ideally suited to their learning styles.
            </ProjectSectionText>
          </ProjectTextRow>
        </ProjectSection>
        <ProjectSection light={isDark}>
          <ProjectSectionContent>
            <CustomCaraousel />

            <ProjectTextRow>
              
            </ProjectTextRow>
            <ProjectTextRow>
              <ProjectSectionHeading>Solution</ProjectSectionHeading>
              <ProjectSectionText>
                Pick A Class offers a seamless solution to this problem by notifying
                students about class openings in under 15 seconds. Our platform constantly
                monitors the class registration system for any changes in availability,
                ensuring that students are immediately informed when a spot opens up in
                their desired class. By providing timely and accurate information, Pick A
                Class empowers students to make informed decisions about their academic
                pursuits and increases their chances of securing a class with their
                preferred professor.
              </ProjectSectionText>
            </ProjectTextRow>
          </ProjectSectionContent>
        </ProjectSection>

        <ThemeProvider themeId="dark"></ThemeProvider>
      </ProjectContainer>
      <EndFooter />
    </Fragment>
  );
};

export default PickAClass;
