import React from 'react';
import { EndFooter } from '../Home/footer/footer';


const CancellationAndRefundPolicy = () => {
  return (
    <>
      <main className="main-wrapper">

        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="privacy-policy-content">
                  <p>
                    This Cancellation & Refund Policy ("Policy") applies to the purchase
                    of software products and services ("Products") from AppJox. By
                    purchasing Products from us, you agree to this Policy and our Terms of
                    Service.
                  </p>
                  <h4>Cancellation</h4>
                  <p>
                    You may cancel your purchase of a Product at any time before the
                    Product is delivered to you. To cancel your purchase, you must contact
                    us at aryash@appjox.com and provide us with your order number. If you
                    cancel your purchase before the Product is delivered to you, we will
                    refund the purchase price in full.
                  </p>
                  <h4>Refunds</h4>
                  <p>
                    If you are not satisfied with a Product that you have purchased, you
                    may request a refund by contacting us at aryash@appjox.com within 30
                    days of the purchase date. We will review your request and determine
                    whether a refund is warranted. If we determine that a refund is
                    warranted, we will process the refund within 14 days of our decision.
                  </p>
                  <h4>Recurring billing</h4>
                  <p>
                    If you have purchased a Product with recurring billing, you may cancel
                    your subscription at any time by contacting us at aryash@appjox.com.
                    If you cancel your subscription, you will not be charged for any
                    future billing cycles. However, we will not issue refunds for any past
                    billing cycles.
                  </p>
                  <h4>Return of physical goods</h4>
                  <p>
                    If you have purchased a physical product from us, you may return the
                    product to us within 30 days of the purchase date for a full refund,
                    as long as the product is in its original packaging and in new, unused
                    condition. You are responsible for paying the shipping costs for
                    returning the product to us.
                  </p>
                  <h4>Disclaimer</h4>
                  <p>
                    We do not guarantee that our Products will meet your specific
                    requirements or that they will be error-free. We will make reasonable
                    efforts to correct any errors that are reported to us. However, we
                    will not issue refunds for Products that do not meet your expectations
                    or that contain errors that are not significant or that can be
                    corrected through the use of updates or patches.
                  </p>
                  <h4>Changes to this Policy</h4>
                  <p>
                    We reserve the right to change this Policy at any time. Any changes to
                    this Policy will be posted on this page and will become effective
                    immediately upon posting. Your continued use of our Products after any
                    such change constitutes your acceptance of the new Policy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </main>
      <EndFooter/>
    </>
  );
};

export default CancellationAndRefundPolicy;
