import React from 'react';
import { CarouselProvider, Slider, DotGroup, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Image from 'components/Image';
import { useTheme } from 'components/ThemeProvider';
import imageSprComponents1 from 'customAssets/wmlWebsite3.png';
import imageSprComponents3 from 'customAssets/wmlWebsite4.jpg';
import imageSprComponents2 from 'customAssets/wmlWebsite2.jpg';
import pac1 from 'assets/pac/pickaclass.png';
import pac2 from 'assets/pac/pac-2.png';
import pac3 from 'assets/pac/pac-3.png';

import imageSprComponentsDarkPlaceholder from 'assets/spr-components-dark-placeholder.png';
import imageSprComponentsLightPlaceholder from 'assets/spr-components-light-placeholder.png';

import './carousel.css';

export const CustomCaraousel = ({ wml, height }) => {
  const { themeId } = useTheme();
  const isDark = themeId === 'dark';

  return (
    <div>
      {' '}
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={height ?? 60}
        totalSlides={3}
        isPlaying={true}
      >
        <Slider>
          <Slide index={0}>
            {' '}
            <Image
              key={'1'}
              srcSet={wml ? imageSprComponents1 : pac1}
              placeholder={
                isDark
                  ? imageSprComponentsDarkPlaceholder
                  : imageSprComponentsLightPlaceholder
              }
              alt={``}
              sizes="100vw"
            />{' '}
          </Slide>
          <Slide index={1}>
            {' '}
            <Image
              key={'2'}
              srcSet={wml ? imageSprComponents2 : pac2}
              placeholder={
                isDark
                  ? imageSprComponentsDarkPlaceholder
                  : imageSprComponentsLightPlaceholder
              }
              alt={``}
            />{' '}
          </Slide>
          <Slide index={2}>
            {' '}
            <Image
              key={'3'}
              srcSet={wml?imageSprComponents3:pac3}
              placeholder={
                isDark
                  ? imageSprComponentsDarkPlaceholder
                  : imageSprComponentsLightPlaceholder
              }
              alt={``}
            />{' '}
          </Slide>
        </Slider>

        <DotGroup className="prc-dotGroup" />
      </CarouselProvider>
    </div>
  );
};
