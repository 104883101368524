import { Button } from 'components/Button';
import React from 'react';
import './index.scss';

export const MoreProjectDivider = () => {
  return (
    <div className="endFooter">
      <h1 style={{ color: 'black', textAlign: 'center' }}>Discover our diverse portfolio</h1>
     
      <div style={{ height: '48px' }} />
      <Button iconHoverShift href="/contact" iconEnd="arrowRight" footerButton={true}>
See all our work      </Button>
      <div className="footer-row">
        <a href="privacy-policy">Privacy Policy</a>
        <a href="terms-and-conditions">Terms And Conditions</a>
        <a href="cancellation-policy">Cancellation Policy</a>
      </div>
    </div>
  );
};
