import { Button } from 'components/Button';
import React from 'react';
import './footer.scss';

export const EndFooter = () => {
  return (
    <div className="endFooter">
      <h1 style={{ color: 'black', textAlign: 'center' }}>Have a Project in Mind?</h1>
      <div style={{ height: '48px' }} />
      <h3 style={{ color: 'black', textAlign: 'center' ,fontWeight:'normal'}}>
        Join us in our mission to solve real-world problems with out-of-the-world solutions.{' '}
      </h3>{' '}
      <div style={{ height: '32px' }} />
      <Button iconHoverShift href="/contact" iconEnd="arrowRight" footerButton={true}>
        Get in touch{' '}
      </Button>
      <div className="footer-row">
        <a href="/privacy-policy">Privacy Policy</a>
        <a href="/terms-and-conditions">Terms And Conditions</a>
        <a href="/cancellation-policy">Cancellation Policy</a>
      </div>
    </div>
  );
};
