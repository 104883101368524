import DecoderText from 'components/DecoderText';
import React from 'react';
import './productionStrategy.scss';
import { ProductionStrategyTiles } from './productionStrategyTiles';
export const ProductionStrategy = () => {
  return (
    <div className="productionStrategy">
      {/* <h1 style={{
        textAlign: 'center', margin: '0px 32px', fontSize: '40px',
      }}>
        Our Process      </h1>
      <div style={{ height: '32px' }} />
      <h3 style={{ textAlign: 'center', margin: '0px 32px', fontWeight: 'normal' }}>
        Utilizing Lean Design Sprints and Agile Application Development,
        our development methodology and tactics guarantee the delivery of top-notch quality products.
      </h3>
      <div style={{ height: '32px' }} />
      <ProductionStrategyTiles />{' '} */}
      <DecoderText
        text="Make Your Vision a Reality."
        className="productionStrategy_decoderText"
        style={{
          justifyContent: 'center',
          textAlign: 'center',
          fontWeight: 'bold',
        }}
        start={
          "true"
        }
        delay={500}
      />
    </div>
  );
};
