
import "./index.scss";
const { ProjectServicesHeader } = require("components/ProjectServicesHeader");
export function ServicesListItem({ title, description, roles, src, isAlternate }) {
    return (
        <div className={`project-add-banner${isAlternate ? '--reverse' : ''}`}>
            <div className="content">
                <ProjectServicesHeader title={title} description={description} roles={roles} />
            </div>
            <div className="thumbnail">
                <img src={src} alt="Product" />
            </div>
        </div>
    );
}