import classNames from 'classnames';
import './index.css';
function Monogram({ highlight, className, ...props }) {

  return (
    <svg
      aria-hidden
      className={classNames('monogram', className)}
      width="46"
      height="29"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 225.4 214.3"
      {...props}
    >
      
      <title>Asset 1</title>
      <path
        d="M77,41.4,15.4,177.9H0C25.6,121,50.9,64.6,77,6.8c23.5,52.4,46.3,103.2,69.5,154.7H63.6c.7-1.8,1.2-3.6,2-5.2,9.9-22,19.9-44,29.7-66.1a9.1,9.1,0,0,0,.2-6.7C89.7,69.8,83.6,56.2,77,41.4Zm7.9,106.3h39.9l-20-44.8Z"
        styles="fill:#fafafa"
      />
      <path
        d="M225.4,213.7c-14.4.7-14.5.8-20.2-11.6C195,180,185,157.8,175,135.7c-15.2-33.5-30.2-66.9-45.3-100.4-5.1-11.5-10-23-15.3-35.1,4.6,0,8.6-.5,12.5.2,1.8.3,3.9,2.6,4.8,4.5,12.6,27.6,24.9,55.4,37.4,83.1s26.2,57.5,39.2,86.3C214.1,187.2,219.6,200.2,225.4,213.7Z"
        styles="fill:#f9f9f9"
      />
      <path
        d="M39,214.3c2.2-4.7,4-8.9,6.1-12.8.5-.9,2.2-1.4,3.4-1.4H164.3l6.3,14.2Z"
        styles="fill:#f1f1f1"
      />
    </svg>
  );
}

export default Monogram;
