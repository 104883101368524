import React from 'react';
// import our-services-SectionTitle from '../../elements/our-services-section-title/our-services-SectionTitle';
import ProjectData from './ProjectData.json';
import './index.scss';
import MockupImage from 'assets/appJoxAsset/imageAssets/project-7.png';
import MockupImage2 from 'assets/appJoxAsset/imageAssets/project-9.png';
import MockupImage3 from 'assets/appJoxAsset/imageAssets/project-12.png';
import DecoderText from 'components/DecoderText';
import { ProjectServicesHeader } from 'components/ProjectServicesHeader';
import { ServicesListItem } from './serviceListItem';

const portfolioData = ProjectData;

export const OurServices = () => {
  return (
    <div className="our-services-section our-services-section-padding-equal bg-color-dark">
      <DecoderText
        text="Services we offer"
        style={{
          justifyContent: 'center',
          textAlign: 'center',
          marginLeft:'5%',
          fontSize: '40px',
          fontWeight: 'bold',
          padding: '0px 32px',
          color: 'rgb(var(--rgbAccent))',
        }}
        delay={500}
      />{' '}
      <div className="container">
        {' '}
        <ServicesListItem
          title={'Product Ideation'}
          description={'We help you turn your vision into reality'}
          roles={['Scoping Session', 'Product Design Process', 'Minimum Viable Product']}
          src={MockupImage}
        />
        <ServicesListItem
          title={'Product Development'}
          description={
            'Our expert team of developers will work with you to build cutting-edge products'
          }
          roles={[
            'Web Development',
            'Mobile Development',
            'Data Science & AI',
            'UX/UI Design',
          ]}
          src={MockupImage2}
          isAlternate={true}
        />
        <ServicesListItem
          title={'Product Improvement'}
          description={'Improve your product experience with our proven competitive strategies'}
          roles={['Code Audit', 'UX/UI Audit']}
          src={MockupImage3}
        />
        <div style={{ height: '64px' }} />
      </div>
    </div>
  );
};
