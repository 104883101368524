import { hydrate, render } from 'react-dom';
import App from './app';

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  if (module.hot) {
    module.hot.accept();
  }
  // console.log('In Render');
  <div> In Render </div>;
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}
