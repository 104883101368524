import DecoderText from 'components/DecoderText';
import React from 'react';
import { ReactComponent as GoogleLogo } from '../../../customAssets/partner_company_logos/google_logo.svg';
import { ReactComponent as MongoLogo } from '../../../customAssets/partner_company_logos/mongodb.svg';
import { ReactComponent as FacebookLogo } from '../../../customAssets/partner_company_logos/facebook_logo.svg';
import { ReactComponent as HerokuLogo } from '../../../customAssets/partner_company_logos/heroku.svg';
import { ReactComponent as AWSLogo } from '../../../customAssets/partner_company_logos/amazon_web_services_logo.svg';
import './index.scss';
export const PartnersSection = () => {
  return (
    <div className="partner_section">
      {/* <DecoderText
        text="Our Partners"
        style={{
          justifyContent: 'center',
          textAlign: 'center',
          fontSize: '40px',
          fontWeight: 'bold',
          padding: '0px 32px',
        }}
        delay={500}
      />{' '} */}
      <div style={{ height: '48px' }} />
      <div className="partner_section_logo_section">
        <div className="partner_section_logo_sub_section">
          <GoogleLogo className="partner_section_brand_logo" />{' '}
          <AWSLogo className="partner_section_brand_logo" />
        </div>
        <div className="partner_section_logo_sub_section">
          <FacebookLogo className="partner_section_brand_logo" />
          <MongoLogo className="partner_section_brand_logo" />
        </div>
        <div className="partner_section_logo_sub_section">
          <HerokuLogo className="partner_section_brand_logo" />
        </div>
      </div>{' '}
      <div style={{ height: '64px' }} />
    </div>
  );
};
