import React from 'react';
import { Transition } from 'react-transition-group';
import Section from 'components/Section';
import { reflow } from 'utils/transition';
import './Clients.css';
import { ClientBox } from 'components/clientBox/clientBox';


export const Clients = (id, visible, sectionRef) => {
  const titleId = `${id}-title`;

  return (
    <Section
      className="clientsClassSection"
      as="section"
      id={id}
      ref={sectionRef}
      aria-labelledby={titleId}
      tabIndex={-1}
    >
      <Transition  timeout={0} onEnter={reflow}>
        <ClientBox/>
      </Transition>
    </Section>
  );
};

