import React from 'react';
import { EndFooter } from './Home/footer/footer';

import './policy.scss';
const PrivacyPolicy = () => {
  return (
    <>
      <main className="main-wrapper">
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="privacy-policy-content">
                  <div className="section-title">
                    <h5 className="title">
                      This Privacy policy was published on January 6th, 2023.
                    </h5>
                  </div>
                  <h4>Introduction</h4>
                  <p>
                    This Privacy Policy explains how AppJox ("we," "us," or "our")
                    collects, uses, and shares information about you when you use our
                    websites, mobile applications, and other online services that link to
                    this Privacy Policy (collectively, the "Services").
                  </p>
                  <h4>Information We Collect</h4>
                  <p>
                    We may collect several types of information from and about you,
                    including:{' '}
                    <ul>
                      <li>
                        Personal information: We may collect personal information such as
                        your name, email address, and phone number when you create an
                        account or otherwise interact with us through the Services.
                      </li>{' '}
                      <li>
                        Payment information: If you purchase a product or service through
                        the Services, we may collect payment information such as your
                        credit card or bank account number. Location information: We may
                        collect information about your location through GPS, Bluetooth, or
                        other technologies when you use our mobile applications or other
                        location-based Services.
                      </li>{' '}
                      <li>
                        Device information: We may collect information about the devices
                        you use to access the Services, including device type, IP address,
                        and unique device identifier.
                      </li>{' '}
                      <li>
                        Usage information: We may collect information about your use of
                        the Services, including the pages you visit and the actions you
                        take.
                      </li>{' '}
                      <li>
                        Cookies and tracking technologies: We and our partners may use
                        cookies and other tracking technologies to collect information
                        about you when you use the Services. This includes information
                        about your browsing and search history, as well as information
                        about your device and internet connection.
                      </li>{' '}
                    </ul>
                  </p>

                  <h4>How We Use Your Information</h4>
                  <p>
                    We may use the information we collect from and about you for a variety
                    of purposes, including:
                  </p>
                  <ul>
                    <li>
                      To provide the Services: We may use your information to provide and
                      improve the Services, including to personalize your experience and
                      to respond to your inquiries.
                    </li>
                    <li>
                      To communicate with you: We may use your contact information to send
                      you updates about the Services or to ask for your feedback.
                    </li>
                    <li>
                      To process payments: We may use your payment information to process
                      transactions and prevent fraud.
                    </li>
                    <li>
                      To analyze and improve the Services: We may use your information to
                      understand how you use the Services and to identify areas for
                      improvement.
                    </li>
                    <li>
                      To comply with legal obligations: We may use your information as
                      necessary to comply with legal obligations such as responding to a
                      subpoena or court order.
                    </li>
                  </ul>
                  <h4>Sharing Your Information</h4>
                  <p className="mb--20">
                    We may share your information with third parties in the following
                    circumstances:
                    <ul>
                      <li>
                        With service providers: We may share your information with third
                        parties who provide services on our behalf, such as payment
                        processors or data analysts.{' '}
                      </li>
                      <li>
                        With business partners: We may share your information with
                        business partners who offer products or services that may be of
                        interest to you.{' '}
                      </li>
                      <li>
                        {' '}
                        With law enforcement or in legal proceedings: We may share your
                        information as required by law or in response to legal process,
                        such as a subpoena or court order.
                      </li>
                      <li>
                        {' '}
                        With third parties in connection with a merger, acquisition, or
                        sale of assets: If we are involved in a merger, acquisition, or
                        sale of assets, we may share your information with the other party
                        or parties involved in the transaction.{' '}
                      </li>
                    </ul>
                  </p>
                  <h4>Your Choices</h4>
                  <p>
                    You have the following options with respect to your information:
                    <li>
                      Opting out of marketing communications: You may opt out of receiving
                      marketing communications from us by following the unsubscribe
                      instructions in the communications you receive or by contacting us
                      at aryash@appjox.com.
                    </li>
                    <li>
                      Accessing and updating your information: You may access and update
                      your account information at any time through the Services or by
                      contacting us at aryash@appjox.com.
                    </li>
                    <li>
                      Deleting your information: You may request that we delete your
                      information by contacting us at aryash@appjox.com.
                    </li>
                  </p>

                  <h4>The use of cookies and web beacons</h4>
                  <p className="mb--20">
                    We may log information using "cookies." Cookies are small data files
                    stored on your hard drive by a website. Cookies help us make our Site
                    and your visit better.
                  </p>
                  <p className="mb--20">
                    may log information using digital images called web beacons on our
                    Site or in our emails.
                  </p>
                  <p>
                    This information is used to make our Site work more efficiently, as
                    well as to provide business and marketing information to the owners of
                    the Site, and to gather such personal data as browser type and
                    operating system, referring page, path through site, domain of ISP,
                    etc. for the purposes of understanding how visitors use our Site.
                    Cookies and similar technologies help us tailor our Site to your
                    personal needs, as well as to detect and prevent security threats and
                    abuse. If used alone, cookies and web beacons do not personally
                    identify you.
                  </p>
                  <h4>How long we keep your data</h4>
                  <p className="mb--20">
                    We store personal data for as long as we find it necessary to fulfill
                    the purpose for which the personal data was collected, while also
                    considering our need to answer your queries or resolve possible
                    problems. This helps us to comply with legal requirements under
                    applicable laws, to attend to any legal claims/complaints, and for
                    safeguarding purposes.
                  </p>
                  <p>
                    We store personal data for as long as we find it necessary to fulfill
                    the purpose for which the personal data was collected, while also
                    considering our need to answer your queries or resolve possible
                    problems. This helps us to comply with legal requirements under
                    applicable laws, to attend to any legal claims/complaints, and for
                    safeguarding purposes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>      <EndFooter />

    </>
  );
};

export default PrivacyPolicy;
