import "./index.css"    ;

export function ClientBoxTile({ title, statement, image, name, companyPosition }) {
    return (
      <div
       
        className="clientBox"
      >
        <img
          src={image}
          alt="Client"
          style={{
            height: '80px',
            width: '80px',
            borderRadius: '5em',
          }}
        />
        <div style={{ height: '32px' }} />
        <h3>{`${title}`}</h3> <div style={{ height: '32px' }} />
        <p>{`${statement}`}</p> <div style={{ height: '32px', }} />
        <h4>{`${name}`}</h4> <div style={{ height: '8px' }} />
        <h4 style={{ textAlign:'center'}}>{`${companyPosition}`}</h4>
      </div>
    );
  }