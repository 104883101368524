import './index.css';
import pickAClass from 'assets/pac/pickaclass.png';

import gamestackTexture2Placeholder from 'assets/gamestack-list-placeholder.jpg';
import gamestackTexture2 from 'customAssets/wmlApp1.jpg';
import gamestackTexturePlaceholder from 'assets/gamestack-login-placeholder.jpg';
import gamestackTexture from 'customAssets/wmlApp2.jpg';
import luxNyc1 from 'assets/luxor/luxnyc1.png';
import luxNyc2 from 'assets/luxor/luxnyc2.png';
import iphone11 from 'assets/iphone-11.glb';
import macbookPro from 'assets/macbook-pro.glb';
import sprTextureLarge from 'customAssets/wmlWebsitePreview.jpg';
import sprTexturePlaceholder from 'assets/spr-lesson-builder-dark-placeholder.jpg';
import sprTexture from 'customAssets/wmlWebsitePreview.jpg';
import { usePrefersReducedMotion, useRouteTransition } from 'hooks';
import Intro from 'pages/Home/Intro';
import Profile from 'pages/Home/Profile';
import ProjectSummary from 'pages/Home/ProjectSummary';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { Clients } from './clientsSection/Clients';
import DecoderText from 'components/DecoderText';
import { ProductionStrategy } from './productionStrategy/productionStrategy';
import { EndFooter } from './footer/footer';
import { PartnersSection } from './partnersSection';
import { OurServices } from './ourServices/ourServices.jsx';
import { MoreProjectDivider } from './moreProjectDivider';

const disciplines = [
  "Turn your business into powerful digital experiences"
];
export const appJoxDescription =
  'AppJox is a remote-first mobile app and web development agency of strategists, designers, and developers operating around the globe.';
export const appJoxTitle = 'AppJox | App and Web Development Agency';

const Home = () => {
  const { status } = useRouteTransition();
  const { hash, state } = useLocation();
  const initHash = useRef(true);
  const [visibleSections, setVisibleSections] = useState([]);
  const [scrollIndicatorHidden, setScrollIndicatorHidden] = useState(true);
  //const intro = useRef();
  const projectOne = useRef();
  const projectTwo = useRef();
  const projectThree = useRef();
  const details = useRef();
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    const revealSections = [
    //  intro,
      projectOne,
      projectTwo,projectThree,
      details,
    ];

    const sectionObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const section = entry.target;
            observer.unobserve(section);
            if (visibleSections.includes(section)) return;
            setVisibleSections(prevSections => [...prevSections, section]);
          }
        });
      },
      { rootMargin: '0px 0px -10% 0px' }
    );

    const indicatorObserver = new IntersectionObserver(
      ([entry]) => {
        setScrollIndicatorHidden(!entry.isIntersecting);
      },
      { rootMargin: '-100% 0px 0px 0px' }
    );

    revealSections.forEach(section => {
      sectionObserver.observe(section.current);
    });


    return () => {
      sectionObserver.disconnect();
      indicatorObserver.disconnect();
    };
  }, [visibleSections]);

  useEffect(() => {
    const hasEntered = status === 'entered';
    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
    let scrollObserver;
    let scrollTimeout;

    const handleHashchange = (hash, scroll) => {
      clearTimeout(scrollTimeout);
      const hashSections = [ projectOne, projectTwo, details];
      const hashString = hash.replace('#', '');
      const element = hashSections.filter(item => item.current.id === hashString)[0];
      if (!element) return;
      const behavior = scroll && !prefersReducedMotion ? 'smooth' : 'instant';
      const top = element.current.offsetTop;

      scrollObserver = new IntersectionObserver(
        (entries, observer) => {
          const [entry] = entries;
          if (entry.isIntersecting) {
            scrollTimeout = setTimeout(
              () => {
                element.current.focus();
              },
              prefersReducedMotion ? 0 : 400
            );
            observer.unobserve(entry.target);
          }
        },
        { rootMargin: '-20% 0px -20% 0px' }
      );

      scrollObserver.observe(element.current);

      if (supportsNativeSmoothScroll) {
        window.scroll({
          top,
          left: 0,
          behavior,
        });
      } else {
        window.scrollTo(0, top);
      }
    };

    if (hash && initHash.current && hasEntered) {
      handleHashchange(hash, false);
      initHash.current = false;
    } else if (!hash && initHash.current && hasEntered) {
      window.scrollTo(0, 0);
      initHash.current = false;
    } else if (hasEntered) {
      handleHashchange(hash, true);
    }

    return () => {
      clearTimeout(scrollTimeout);
      if (scrollObserver) {
        scrollObserver.disconnect();
      }
    };
  }, [hash, state, prefersReducedMotion, status]);

  return (
    <div className="home">
      <Helmet>
        <title>{appJoxTitle}</title>
        <meta name="description" content={appJoxDescription} />
        <meta name="og:description" content={appJoxDescription} />
        <meta
          property="og:image"
          content="https://i.ibb.co/WtQsRdm/Screenshot-2022-02-15-204105.png"
        />{' '}
        <meta property="og:title" content={appJoxTitle} />
        <link rel="prefetch" href={iphone11} as="fetch" crossorigin="" />
        <meta name="twitter:description" content={appJoxDescription} />
        <meta name="twitter:title" content={appJoxTitle} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content="https://i.ibb.co/WtQsRdm/Screenshot-2022-02-15-204105.png"
        />
        <link rel="prefetch" href={macbookPro} as="fetch" crossorigin="" />
      </Helmet>
      <Intro
        id="intro"
        disciplines={disciplines}
        scrollIndicatorHidden={scrollIndicatorHidden}
        
      />
      <PartnersSection/>
      <OurServices/>
      <ProjectSummary
        id="project-1"
        sectionRef={projectOne}
        visible={visibleSections.includes(projectOne.current)}
        index={1}
        title="Wicked Maine Lobster"
        description="See how our highly scalable web and mobile apps helped Wicked Maine Lobster scale to $1M in annual revenue."
        buttonText="View case study"
        buttonLink="/projects/wickedmainelobster"
        model={{
          type: 'laptop',
          alt: 'Wicked Maine Lobster Website',
          textures: [
            {
              src: sprTexture,
              srcSet: `${sprTextureLarge} 800w, ${sprTextureLarge} 1440w`,
              placeholder: sprTexturePlaceholder,
            },
          ],
        }}
      />
      <ProjectSummary
        id="project-2"
        alternate
        sectionRef={projectTwo}
        visible={visibleSections.includes(projectTwo.current)}
        index={2}
        title="Luxor Studios"
        description="Learn how we boosted Luxor Studios' revenue by 30% with a custom mobile app."
      
        model={{
          type: 'phone',
          alt: 'Luxor Studios App',
          textures: [
            {
              src: luxNyc1,
              srcSet: { gamestackTexture },
              placeholder: gamestackTexturePlaceholder,
            },
            {
              src: luxNyc2,
              srcSet: { gamestackTexture2 },
              placeholder: gamestackTexture2Placeholder,
            },
          ],
        }}
      />{' '}
 <ProjectSummary
        id="project-3"
        sectionRef={projectThree}
        visible={visibleSections.includes(projectThree.current)}
        index={3}
        title="Pick A Class"
        description="Discover how our lightning-fast backend solutions, paired with a web app, enabled EdTech startup Pick-A-Class to acquire their first 2000 customers."
       
        model={{
          type: 'laptop',
          alt: 'Pick A Class Website',
          textures: [
            {
              src: sprTexture,
              srcSet: `${pickAClass} 800w, ${pickAClass} 1440w`,
              placeholder: sprTexturePlaceholder,
            },
          ],
        }}
      />
      <div
        style={{
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
        }}
      >
{/* <MoreProjectDivider/> */}
        
        <div style={{ height: '64px' }} />
        <DecoderText
          text="What our clients say about us.
          "
          style={{
            justifyContent: 'center',
            textAlign: 'center',
            fontSize: '40px',
            fontWeight: 'bold',
            margin: '0px 16px',
          }}
          start={status !== 'exited'}
          delay={500}
        />{' '}
        <div style={{ height: '32px' }} />
        <h3
          start={status !== 'exited'}
          style={{
            textAlign: 'center',
            margin: '0px 32px',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'normal',
          }}
          delay={500}
        >
          {' '}
          We believe that we succeed when our clients succeed. Here's what our clients have to say about us.
        </h3>
      </div>
      <Clients
        sectionRef={details}
        visible={visibleSections.includes(details.current)}
        id="details"
      />{' '}
      <ProductionStrategy />
      <Profile
        sectionRef={details}
        visible={visibleSections.includes(details.current)}
        id="details"
      />
      <EndFooter />
    </div>
  );
};

export default Home;
