import { ClientBoxTile } from 'components/clientBoxTile';
import React from 'react';
import './clientBox.css';
import { useRef, useEffect } from 'react';
import { ButtonBack, ButtonFirst, ButtonLast, ButtonNext, CarouselProvider, DotGroup, Image, Slide, Slider } from 'pure-react-carousel';

import 'pure-react-carousel/dist/react-carousel.es.css';
import { useState } from 'react';
import { alexImage } from 'assets/client-images/export';


export function ClientBox() {    const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {

    setWidth(window.innerWidth);
  }
  const containerRef = useRef(null);
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 798;
  const isTablet = width >= 799 && width <= 1000;
  const isDesktop = width >= 1208;
  useEffect(() => {
    const intervalId = setInterval(() => {
      const container = containerRef.current;
      if (container) {
        container.scrollLeft += 1;
      }


    }, 10);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <CarouselProvider
      visibleSlides={(isMobile||isTablet)?1:2}
      totalSlides={3}
      naturalSlideWidth={800}
      naturalSlideHeight={(isMobile)?1600 :isTablet?800:600}
      style={{ width: "100vw" }}
      infinite
      isPlaying
      interval={5000}
    >

      <Slider>
        <Slide tag="a" index={0}>
          <ClientBoxTile
            title="One Of The Hardest Working Groups"
            name={'Alexander Howard'}
            companyPosition="Founder & CEO, Wicked Maine Lobster"
            image={alexImage}
            statement="“Undoubtedly one of the hardest working groups of individuals I have ever had the pleasure of working with. AppJox not only exemplifies how creative they can be, but they also provide excellent communication and shows attentiveness to their customers.”" />     </Slide>
        <Slide tag="a" index={1}>
          <ClientBoxTile
            title="NYC now have “GO-TO” App Developers"
            image="https://firebasestorage.googleapis.com/v0/b/appjox-fa46b.appspot.com/o/169866_193529793996232_193529187329626_839508_2452737_o_1297668525_1297668550.jpg?alt=media&token=a0bb9cd3-8633-4949-8dc0-fdd6fc6ea190"
            name={'Kobe Jamero'}
            companyPosition="Founder & CEO, Luxor Studios"
            statement="“In a society where it's hard to find hardworking and dedicated individuals' that live for their work and will work endlessly with your vision in mind, I’m personally honored to have worked with AppJox. The music industry in the city of New York now have “GO-TO” app developers.”" />        </Slide>
        <Slide tag="a" index={2}>
          <ClientBoxTile
            title="“If it can be built, we can build it.”"
            image="https://firebasestorage.googleapis.com/v0/b/appjox-fa46b.appspot.com/o/1662158332755.jpeg?alt=media&token=216dcd01-f99d-4a15-94a1-2a8d7c545ecd"
            name={'Zachary Myers'}
            companyPosition="Founder & CEO, Luxor Studios"
            statement="
            “The AppJox team is honest, incredibly hardworking, and consistently ensures that you are satisfied with your project. Throughout the development of Fuddle, I would continually ask them if they could build features that I thought were nearly impossible. Their response was always the same - “If it can be built, we can build it.” Time and time again, they proved that to be true. Their abilities and problem-solving skills are truly a sight to behold.”" />          </Slide>
                    </Slider>

    </CarouselProvider>
  );
}


function MyComponent() {
  const containerRef = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const container = containerRef.current;
      if (container) {
        container.scrollTop += 1;
      }
    }, 10); // adjust the scroll speed as needed

    return () => clearInterval(intervalId);
  }, []);

  // render the list container and its contents
  return (
    <div ref={containerRef} style={{ overflowY: 'scroll', height: '200px' }}>
      <ul>
        <li>Item 1</li>
        <li>Item 2</li>
        <li>Item 3</li>
        ...
      </ul>
    </div>
  );
}