export const navLinks = [
  {
    label: 'Projects',
    pathname: '/',
    hash: '#project-1',
  },
 
  {
    label: 'Blogs',
    pathname: '/blogs',
  },
  {
    label: 'Contact',
    pathname: '/contact',
  },
];

export const socialLinks = [
  {
    label: 'Instagram',
    url: 'https://www.instagram.com/dubeyaryash/?hl=en',
    icon: 'instagram',
  },
  {
    label: 'LinkedIn',
    url: 'https://www.linkedin.com/in/aryash-dubey-app-developer/',
    icon: 'linkedIn',
  },

  {
    label: 'Github',
    url: 'https://github.com/AryashDubey',
    icon: 'github',
  },
];
