import React from 'react';
import productStrategyTile1 from '../../../assets/appJoxAsset/svgAssets/productStrategy.svg'; // with import
import productStrategyTile2 from '../../../assets/appJoxAsset/svgAssets/productDesign.svg'; // with import
import productStrategyTile3 from '../../../assets/appJoxAsset/svgAssets/productDevelopment.svg'; // with import
import './productionStrategyTiles.scss';

function ProductStrategyIndividualTile({ src, heading, paragraph }) {
  return (
    <div className="productionStrategyIndividualTile">
      <img className="productionStrategySvg" src={src} alt="Product Strategy 1" />
      <div
        style={{
          height: '16px',
        }}
      />
      <h3
        style={{
          lineHeight: '24x',
          textAlign: 'center',
        }}
      >
        {heading}{' '}
      </h3>
      <div
        style={{
          height: '16px',
        }}
      />
      <p
        style={{
          lineHeight: '24px',
          textAlign: 'center',
        }}
      >
        {' '}
        {paragraph}
      </p>
    </div>
  );
}

export const ProductionStrategyTiles = () => {
  return (
    <div className="productionStrategyTiles">
      <ProductStrategyIndividualTile
        src={productStrategyTile1}
        heading="1. Product Strategy"
        paragraph="We work with you to understand the problem you’re trying to solve and the digital
        product solution to that problem. This involves lots and lots of research and
        iterative user testing."
      />

      <ProductStrategyIndividualTile
        src={productStrategyTile2}
        heading="2. Product Design & User Testing"
        paragraph="Expanding on the insights gained, you’ll work closely with our app development team to create an elegant design that incorporates best UI/UX practices and trends. And user testing, user testing, and user testing. Did we mention user testing?        "
      />
      <ProductStrategyIndividualTile
        src={productStrategyTile3}
        heading="3. Product Development"
        paragraph="With our scrum-based agile methodology, you’ll receive iterative builds every two weeks, which gives you plenty of time to test them out and make adjustments. We’re here for ongoing and long-term support and maintenance."
      />
    </div>
  );
};
