import React from 'react';
import { EndFooter } from './Home/footer/footer';

import './policy.scss';

const TermsOfUse = () => {
  return (
    <>
      <main className="main-wrapper">
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="privacy-policy-content">
                  <p>
                    These terms and conditions ("Terms") apply to the use of the AppJox
                    website and services (the "Services"). By accessing or using the
                    Services, you agree to be bound by these Terms and our Privacy Policy,
                    which is hereby incorporated by reference. If you do not agree to
                    these Terms or the Privacy Policy, please do not use the Services.
                  </p>
                  <h4>Eligibility</h4>
                  <p>
                    The Services are intended for use by individuals who are at least 18
                    years of age. By using the Services, you represent and warrant that
                    you are at least 18 years of age and have the legal capacity to enter
                    into a contract.
                  </p>
                  <h4>Modification of the Services and these Terms</h4>
                  <p>
                    We reserve the right to modify or discontinue the Services or any
                    portion thereof at any time, with or without notice to you. We also
                    reserve the right to modify these Terms at any time, and will provide
                    notice of such modifications through the Services or by other means.
                    Your continued use of the Services after any such modification
                    constitutes your acceptance of the new Terms.
                  </p>
                  <h4>Account Registration and Security</h4>
                  <p>
                    In order to access certain features of the Services, you may be
                    required to create an account. You are responsible for maintaining the
                    confidentiality of your account and password, and you agree to accept
                    responsibility for all activities that occur under your account. You
                    should immediately notify us of any unauthorized use of your account
                    or any other breach of security.
                  </p>
                  <h4>Intellectual Property</h4>
                  <p>
                    The Services and all materials contained on the Services, including
                    text, images, software, and other content, are protected by copyright
                    and other intellectual property laws. You may use the Services and the
                    materials contained on the Services for your personal, non-commercial
                    use only. You may not use the Services or any materials contained on
                    the Services for any other purpose without the prior written consent
                    of AppJox.
                  </p>
                  <h4>Disclaimer of Warranties</h4>
                  <p>
                    The Services are provided on an "as is" and "as available" basis.
                    AppJox makes no representations or warranties of any kind, express or
                    implied, as to the operation of the Services or the information,
                    content, materials, or products included on the Services. To the full
                    extent permissible by applicable law, AppJox disclaims all warranties,
                    express or implied, including, but not limited to, implied warranties
                    of merchantability and fitness for a particular purpose. AppJox will
                    not be liable for any damages of any kind arising from the use of the
                    Services, including, but not limited to, direct, indirect, incidental,
                    punitive, and consequential damages.
                  </p>
                  <h4>Limitation of Liability</h4>
                  <p>
                    AppJox shall not be liable for any damages of any kind arising from
                    the use of the Services, including, but not limited to, direct,
                    indirect, incidental, punitive, and consequential damages.
                  </p>
                  <h4>Indemnification</h4>
                  <p>
                    You agree to indemnify and hold AppJox, and its affiliates, officers,
                    agents, and employees, harmless from any claim or demand, including
                    reasonable attorneys' fees, made by any third party due to or arising
                    out of your use of the Services, your violation of these Terms, or
                    your violation of any rights of another.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </main>
      <EndFooter/>
    </>
  );
};

export default TermsOfUse;
