import { lazy, Suspense, useEffect, createContext, useReducer, Fragment } from 'react';
import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';
import { Transition, TransitionGroup } from 'react-transition-group';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import Navbar from 'components/Navbar';
import ThemeProvider from 'components/ThemeProvider';
import { tokens } from 'components/ThemeProvider/theme';
import VisuallyHidden from 'components/VisuallyHidden';
import { useLocalStorage } from 'hooks';
import { msToNum } from 'utils/style';
import { reflow } from 'utils/transition';
import prerender from 'utils/prerender';
import { initialState, reducer } from 'app/reducer';
import './reset.css';
import './index.css';
import ReactGA from 'react-ga';
import { useState } from 'react';
import { getCategoryQuery, getPostQuery } from 'services';
import axios from 'axios';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import TermsOfUse from 'pages/TermsOfUse';
import CancellationAndRefundPolicy from 'pages/WMLWeb/CancellationPolicy';
import PickAClass from 'pages/pac';

const Home = lazy(() => import('pages/Home'));
const Contact = lazy(() => import('pages/Contact'));const Luxor = lazy(() => import('pages/LuxorNYC'));

const WMLWeb = lazy(() => import('pages/WMLWeb/index.jsx'));
const WMLApp = lazy(() => import('pages/WMLApp'));
const Page404 = lazy(() => import('pages/404'));
const Blog = lazy(() => import('pages/blogs'));
const Twick = lazy(() => import('pages/Twick'));
const BlogDetailPage = lazy(() => import('pages/blogDetailPage'));
export const BlogContext = createContext({});
export const AppContext = createContext({});
export const TransitionContext = createContext();
const graphqlAPI =
  'https://api-ap-south-1.graphcms.com/v2/ckz7dktoh0bvz01z7a8s08zw9/master';

const repoPrompt = ``;

const App = () => {
  const [storedTheme] = useLocalStorage('theme', 'light');
  const [state, dispatch] = useReducer(reducer, initialState);
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [postCallDone, setPostCallDone] = useState(false);
  useEffect(() => {
    ReactGA.initialize('UA-219315983-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  useEffect(() => {
    getPosts();
  }, []);
  async function getPosts() {
    try {
      await axios({
        url: graphqlAPI,
        method: 'post',
        data: {
          query: getPostQuery,
        },
      }).then(result => {
        const data = result.data.data.postsConnection.edges;
        let posts = data.map(({ node }) => node);

        posts.sort(function (a, b) {
          var dateA = new Date(a.createdAt),
            dateB = new Date(b.createdAt);
          return dateB - dateA;
        });

        setPosts(posts);
      });
    } catch { }
    setPostCallDone(true);

    console.log('set to true');
  }

  useEffect(() => {
    axios({
      url: graphqlAPI,
      method: 'post',
      data: {
        query: getCategoryQuery,
      },
    }).then(result => {
      const data = result.data.data.categories;

      setCategories(data);
    });
  }, []);

  useEffect(() => {
    if (!prerender) {
      console.info(`${repoPrompt}\n\n`);
    }
    window.history.scrollRestoration = 'manual';
  }, []);

  useEffect(() => {
    dispatch({ type: 'setTheme', value: storedTheme });
  }, [storedTheme]);

  return (
    <BlogContext.Provider
      value={{ posts, setPosts, postCallDone, categories, setCategories }}
    >
      <AppContext.Provider value={{ ...state, dispatch }}>
        <ThemeProvider themeId={state.theme}>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </ThemeProvider>
      </AppContext.Provider>
    </BlogContext.Provider>
  );
};

const AppRoutes = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <Fragment>
      <Helmet></Helmet>
      <VisuallyHidden showOnFocus as="a" className="skip-to-main" href="#MainContent">
        Skip to main content
      </VisuallyHidden>
      <Navbar location={location} />
      <TransitionGroup component="main" className="app" tabIndex={-1} id="MainContent">
        <Transition
          key={pathname}
          timeout={msToNum(tokens.base.durationS)}
          onEnter={reflow}
        >
          {status => (
            <TransitionContext.Provider value={{ status }}>
              <div className={classNames('app__page', `app__page--${status}`)}>
                <Suspense fallback={<Fragment />}>
                  <Switch location={location}>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/contact" component={Contact} />{' '}

                    <Route exact path="/projects/wickedMaineLobster" component={WMLWeb} />
                    <Route
                      exact
                      path="/projects/wickedMaineLobsterApp"
                      component={WMLApp}
                    />                    
                    <Route exact path="/projects/pick-a-class" component={PickAClass} />
                    <Route exact path="/projects/lux-nyc" component={Luxor} />

                    <Route
                      exact
                      path="/blogs"
                      component={Blog}
                    />
                    <Route
                      exact
                      path="/blogs/:category/:blogId"
                      component={BlogDetailPage}
                    />
                    <Route exact path="/projects/twick" component={Twick} />
                    <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                    <Route
                      exact
                      path="/terms-and-conditions"
                      component={TermsOfUse}
                    />{' '}
                    <Route
                      exact
                      path="/cancellation-policy"
                      component={CancellationAndRefundPolicy}
                    />
                    <Route component={Page404} />
                  </Switch>
                </Suspense>
              </div>
            </TransitionContext.Provider>
          )}
        </Transition>
      </TransitionGroup>
    </Fragment>
  );
};

export default App;
